import React, { useState } from "react"
import classnames from "classnames"

import { motion, AnimatePresence } from "framer-motion"
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Hook(s) */
import { usePodcastsPage, usePodcasts } from "~queries/hooks"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Slideshow from "~components/slideshow/slideshow"

/* Import Page Component(s) */
import PodcastCard from "~components/pages/podcast/components/podcastCard/podcastCard"

/* Import Local Style(s) */
import "./podcast.scss"

const Podcasts = ({ location }) => {
  const { pageTitle, slideshow } = usePodcastsPage()
  const podcastsByYear = usePodcasts()
  const podcasts = podcastsByYear[0].posts

  const [yearIndex, setYearIndex] = useState(0)

  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="podcasts">
        {slideshow.images.length > 0 ? (
          <Slideshow slideshow={slideshow} />
        ) : null}
        <PageArticleSection className="podcasts">
          <div className="podcasts--grid">
            {podcasts.map(
              ({
                _id,
                episode,
                color: {
                  color: { hex },
                },
                title,
                publicationDate,
              }) => (
                <PodcastCard
                  key={_id}
                  episode={episode}
                  backgroundColor={hex}
                  title={title}
                  date={publicationDate}
                />
              )
            )}
          </div>


          <div className="time-display">
            <ul className="years">
              {podcastsByYear.length > 1 && <li className="font-size--small">Past Years: </li>}
              {podcastsByYear.map((p, i) => ( (i > 0) ?
                <li key={p.year.toString()} className="font-size--small" ><button
                  className={classnames({ active: yearIndex === i })}
                  onClick={() => {
                    (yearIndex === i) ? setYearIndex(0) : setYearIndex(i)
                  }}
                >{p.year}</button></li>
              : ''))}
            </ul>
          </div>

          <AnimatePresence exitBeforeEnter>
            {(yearIndex !== 0 && <motion.div
              key="grid"
              initial="initial"
              animate="animate"
              exit="exit"
              className="podcasts--grid"
              variants={elementTransitionAnimation.variants}
            >
              {(yearIndex !== 0) ? podcastsByYear[yearIndex].posts.map(({
                _id,
                episode,
                color: {
                  color: { hex },
                },
                title,
                publicationDate,
              }) => (
                <PodcastCard
                  key={_id}
                  episode={episode}
                  backgroundColor={hex}
                  title={title}
                  date={publicationDate}
                />
              )) : ''}
            </motion.div>)}
          </AnimatePresence>

        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Podcasts
